<template>
  <div id="register">
    <top-nav></top-nav>
    <b-row>
      <b-col
        xl="4"
        offset-xl="4"
        lg="6"
        offset-lg="3"
        md="8"
        offset-md="2"
        sm="12"
        cols="8"
        offset="2"
      >
        <h3 class="text-lg-left mt-5"><b>ลงทะเบียน</b></h3>
        <hr style="border: 1px solid #de7033" />
      </b-col>
    </b-row>
    <!--    <b-row>-->
    <!--      <b-col-->
    <!--        xl="4"-->
    <!--        offset-xl="4"-->
    <!--        lg="6"-->
    <!--        offset-lg="3"-->
    <!--        md="8"-->
    <!--        offset-md="2"-->
    <!--        sm="12"-->
    <!--        cols="8"-->
    <!--        offset="2"-->
    <!--        class="mb-3"-->
    <!--      >-->
    <!--        <b-row>-->
    <!--          <b-col lg="6" md="6" sm="6" cols="12" class="mb-2">-->
    <!--            <Facebook />-->
    <!--          </b-col>-->
    <!--          <b-col lg="6" md="6" sm="6" cols="12" class="mb-2">-->
    <!--            <Google />-->
    <!--          </b-col>-->
    <!--        </b-row>-->
    <!--      </b-col>-->
    <!--    </b-row>-->
    <b-row>
      <b-col
        xl="4"
        offset-xl="4"
        lg="6"
        offset-lg="3"
        md="8"
        offset-md="2"
        sm="12"
        cols="8"
        offset="2"
        class="mb-3"
      >
        <b-form-group
          id="field-firstname"
          label="ชื่อ"
          label-for="firstname"
          :state="stateFirstname"
        >
          <!--          :invalid-feedback="invalidFeedback"-->
          <b-form-input
            id="firstname"
            placeholder="ชื่อ"
            v-model="firstname"
            :state="stateFirstname"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="field-lastname"
          label="นามสกุล"
          label-for="lastname"
          :state="stateLastName"
        >
          <b-form-input
            id="lastname"
            placeholder="นามสกุล"
            v-model="lastname"
            :state="stateLastName"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="field-birthDate"
          label="วัน/เดือน/ปีเกิด"
          label-for="birthDate"
          :state="stateBirthDate"
        >
          <b-form-datepicker
            :max="max"
            type="date"
            v-model="birthDate"
            id="birthDate"
            :state="stateBirthDate"
            locale="th"
            close-button
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            trim
          ></b-form-datepicker>
        </b-form-group>
        <!--        <b-form-group-->
        <!--          id="field-CitizenId"-->
        <!--          label="รหัสบัตรประชาชน 13 หลัก"-->
        <!--          label-for="citizen_id"-->
        <!--          :invalid-feedback="invalidFeedbackCitizenID"-->
        <!--          :state="stateCitizen_id"-->
        <!--        >-->
        <!--          <b-form-input-->
        <!--            id="citizen_id"-->
        <!--            placeholder="x-xxxx-xxxxx-xx-x"-->
        <!--            maxlength="13"-->
        <!--            v-model="citizen_id"-->
        <!--            :state="stateCitizen_id"-->
        <!--            trim-->
        <!--          ></b-form-input>-->
        <!--        </b-form-group>-->
        <!--        <b-form-group id="field-gender" label="เพศ" label-for="gender">-->
        <!--          <b-form-radio-group-->
        <!--            v-model="gender"-->
        <!--            :options="options"-->
        <!--            class="mx-3"-->
        <!--            value-field="item"-->
        <!--            text-field="name"-->
        <!--          ></b-form-radio-group>-->
        <!--        </b-form-group>-->
        <b-form-group
          id="field-email"
          label="อีเมล"
          label-for="email"
          :state="stateEmail"
        >
          <b-form-input
            id="email"
            placeholder="example@email.com"
            v-model="email"
            :state="stateEmail"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group
          id="field-password"
          label="รหัสผ่าน"
          label-for="password"
          :state="statePassword"
        >
          <b-form-input
            id="password"
            type="password"
            minlength="8"
            placeholder="รหัสผ่านควรประกอบด้วยตัวอักษร(a-z,A-Z)ตัวเลข(0-9) ความยาว 8 ตัวอักษร"
            v-model="password"
            :state="statePassword"
            trim
          ></b-form-input>
          <label
            ><input class="my-2" type="checkbox" @click="showPassword()" />
            แสดงรหัสผ่าน</label
          >
        </b-form-group>
        <b-form-group
          id="field-confirmpassword"
          label="ยืนยันรหัสผ่าน"
          label-for="confirmpassword"
          :state="stateConfirmPassword"
        >
          <b-form-input
            id="confirmpassword"
            type="password"
            minlength="8"
            placeholder="กรอกรหัสผ่านให้ตรงกัน"
            v-model="confirmpassword"
            :state="stateConfirmPassword"
            trim
          ></b-form-input>
        </b-form-group>
        <!--        <b-form-group-->
        <!--          id="field-phone"-->
        <!--          label="เบอร์โทรศัพท์มือถือ"-->
        <!--          maxlength="10"-->
        <!--          label-for="phone"-->
        <!--          :invalid-feedback="invalidFeedback"-->
        <!--          :state="statePhone"-->
        <!--        >-->
        <!--          <b-form-input-->
        <!--            id="phone"-->
        <!--            placeholder="เบอร์โทรศัพท์มือถือ"-->
        <!--            v-model="phone"-->
        <!--            :state="statePhone"-->
        <!--            trim-->
        <!--          ></b-form-input>-->
        <!--        </b-form-group>-->
      </b-col>
    </b-row>
    <b-row>
      <b-col
        xl="4"
        offset-xl="4"
        lg="6"
        offset-lg="3"
        md="8"
        offset-md="2"
        sm="12"
        cols="8"
        offset="2"
        class="mb-3"
      >
        <b-button
          @click="submit()"
          id="btnSubmit"
          class="btn btnHover btn-outline-light btn-block my-5"
          style="color: black; background-color: white"
          >ลงทะเบียน</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import TopNav from "@/components/topNav";
import Swal from "sweetalert2";
// import Google from "@/components/SocialLogin/Google";
// import Facebook from "@/components/SocialLogin/Facebook";
export default {
  components: {
    TopNav,
    // Google,
    // Facebook
  },
  computed: {
    stateFirstname() {
      return this.firstname.length > 0;
    },
    stateLastName() {
      return this.lastname.length > 0;
    },
    stateBirthDate() {
      return this.birthDate != "";
    },
    // stateCitizen_id() {
    //   if (this.citizen_id.length == 13) {
    //     let val = this.citizen_id;
    //     if (/^[1-9]\d*$|^$/.test(val)) {
    //       if (val.length === 13) {
    //         var total = 0;
    //         var chk;
    //         var Validchk = val.substr(12, 1);
    //         var j = 0;
    //         var pidcut;
    //         for (var n = 0; n < 12; n++) {
    //           pidcut = parseInt(val.substr(j, 1));
    //           total = total + pidcut * (13 - n);
    //           j++;
    //         }
    //         chk = 11 - (total % 11);
    //         if (chk == 10) {
    //           chk = 0;
    //         } else if (chk == 11) {
    //           chk = 1;
    //         }
    //         if (chk != Validchk) {
    //           return false;
    //         } else {
    //           return true;
    //         }
    //       }
    //     }
    //   }
    //   return false;
    // },
    stateEmail() {
      if (this.email && this.email != "") {
        return this.email == ""
          ? ""
          : this.regEmail.test(this.email)
          ? true
          : false;
      } else {
        return false;
      }
    },
    statePassword() {
      if (this.password && this.password != "") {
        if (this.password.length >= 8) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    stateConfirmPassword() {
      if (this.password && this.password != "") {
        if (this.confirmpassword === this.password) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    invalidFeedback() {
      if (this.firstname.length >= 0) {
        return "โปรดกรอกข้อมูล";
      }
      return "โปรดกรอกข้อมูล";
    },
    invalidFeedbackPassword() {
      if (this.confirmpassword != this.password) {
        return false;
      }
      return true;
    },
    invalidFeedbackEmail() {
      let email = this.email;
      if (email) {
        let check_email = this.regEmail.test(email);
        if (!check_email) {
          return "โปรดกรอกข้อมูลให้ถูกต้อง";
        }
      }
      return "โปรดกรอกข้อมูล";
    },
    // invalidFeedbackCitizenID() {
    //   let val = this.citizen_id;
    //   if (/^[1-9]\d*$|^$/.test(val)) {
    //     if (val.length === 13) {
    //       var total = 0;
    //       var chk;
    //       var Validchk = val.substr(12, 1);
    //       var j = 0;
    //       var pidcut;
    //       for (var n = 0; n < 12; n++) {
    //         pidcut = parseInt(val.substr(j, 1));
    //         total = total + pidcut * (13 - n);
    //         j++;
    //       }
    //       chk = 11 - (total % 11);
    //       if (chk == 10) {
    //         chk = 0;
    //       } else if (chk == 11) {
    //         chk = 1;
    //       }
    //       if (chk != Validchk) {
    //         return "ระบุหมายเลขประจำตัวประชาชนไม่ถูกต้อง";
    //       }
    //     }
    //   }
    //   return "โปรดกรอกข้อมูล";
    // },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    return {
      // min: minDate,
      max: today,
      regEmail: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      firstname: "",
      lastname: "",
      citizen_id: "",
      password: "",
      confirmpassword: "",
      email: "",
      phone: "",
      gender: "",
      address: "-",
      birthDate: "",
      options: [
        { item: "male", name: "ชาย" },
        { item: "female", name: "หญิง" },
        // { item: "other", name: "อื่นๆ" },
      ],
    };
  },
  methods: {
    showPassword: function () {
      var passwordInput = document.getElementById("password");
      if (passwordInput.type == "password") {
        passwordInput.type = "text";
      } else {
        passwordInput.type = "password";
      }
    },
    async submit() {
      let data = {
        first_name: this.firstname,
        last_name: this.lastname,
        // citizen_id: this.citizen_id,
        email: this.email,
        password: this.password,
        birth_date: this.birthDate,
        // phone: this.phone,
        // gender: this.gender,
        // address: this.address,
      };

      let validateResult = this.validateForm(data);
      if (validateResult) {
        await this.goRegister(data);
      }
    },
    validateForm(data) {
      let check_email = this.regEmail.test(data.email);
      for (var index in data) {
        var attr = data[index];
        if (!attr && attr == "") {
          Swal.fire("ผิดพลาด!", "โปรดกรอกข้อมูลให้ครบ", "error");
          return false;
        } else if (!check_email) {
          Swal.fire("ผิดพลาด!", "โปรดกรอกอีเมลให้ถูกต้อง", "error");
          return false;
        } else if (this.confirmpassword != data.password) {
          Swal.fire("ผิดพลาด!", "โปรดกรอกรหัสให้ตรงกัน", "error");
          return false;
        } else if (!data.birth_date || data.birth_date == "") {
          Swal.fire("ผิดพลาด!", "โปรดกรอก วัน/เดือน/ปีเกิด", "error");
          return false;
        }
      }
      return true;
    },
    async goRegister(data) {
      const path = `/login`;
      await this.$store.dispatch("Auth/register", data).then((response) => {
        if (response.code == 200) {
          console.log("response", response);
          Swal.fire({
            title: "สำเร็จ!",
            text: "ลงทะเบียนเรียบร้อย",
            confirmButtonText: `ตกลง`,
            icon: "success",
          }).then((result) => {
            if (result.isConfirmed) {
              this.$router.push(path);
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss">
#register {
  height: 100%;
  min-height: 100vh;
}
</style>
