<template>
  <div class="coursesPage">
    <topNav />
    <!--    <sideMenuMobile />-->
    <!--    <adsCouse />-->
    <b-row>
      <b-col lg="2">
        <!--        <sideMenu />-->
      </b-col>
      <b-col lg="10" md="10" offset-md="1" sm="10" cols="12">
        <CourseContent />
      </b-col>
    </b-row>
  </div>
</template>

<script>
// @ is an alias to /src
import topNav from "@/components/topNav.vue";
// import sideMenuMobile from "@/components/sideMenuMobile.vue";
// import sideMenu from "@/components/sideMenu.vue";
import CourseContent from "@/components/CourseContent.vue";

export default {
  name: "coursesPage",
  components: {
    topNav,
    CourseContent,
    // sideMenuMobile,
    // sideMenu,
  },
};
</script>
<style>
.coursesPage {
  height: 100%;
  min-height: 100vh;
}
</style>
