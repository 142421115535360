<template>
  <div class="teacher">
    <b-card class="mb-2 card-teacher block">
      <b-card-title>
        <b-avatar :src="img" size="8rem"></b-avatar>
      </b-card-title>
      <b-card-text>
        {{ "ผู้สอน : " + dataTeacher.first_name + " " + dataTeacher.last_name }}
      </b-card-text>

      <b-button href="#" variant="light" class="btn-light btnHover"
        >ดูเพิ่มเติม</b-button
      >
    </b-card>
  </div>
</template>
<script>
import img from "./../../public/temp.jpg";
export default {
  name: "teacher",

  data() {
    return {
      img: img,
    };
  },
  props: {
    dataTeacher: null,
  },
};
</script>
<style lang="scss">
.teacher {
  height: 100%;
}
.card-teacher {
  text-align: center;
  border: #c8d0d3 1px solid;
  height: 100%;
}
</style>
