<template>
  <div class="footer">
    <b-container class="bv-example-row footer">
      <b-row>
        <b-col>
          <p>
            <img width="80" alt="logo" src="https://img1.wxzxzj.com/vue-core-video-player-logo-white.png" />
          </p>
          <p>Copyright © 2020 CORE-PLAYER</p>
        </b-col>
        <b-col>
          <p class="title">Docs</p>
          <p><a href="https://github.com/core-player/vue-core-video-player/tree/master/docs/en#getting-started" target="_blank">Installation</a></p>
          <p><a href="https://github.com/core-player/vue-core-video-player/tree/master/docs/en#basic-configuration" target="_blank">Configuration</a></p>
          <p><a href="https://github.com/core-player/vue-core-video-player/tree/master/docs/en#Events" target="_blank">Events</a></p>
          <p><a href="https://github.com/core-player/vue-core-video-player/tree/master/docs/en#i18n" target="_blank">i18n</a></p>
        </b-col>
        <b-col>
          <p class="title">CHANNELS</p>
          <p><a href="https://github.com/core-player/vue-core-video-player" target="_blank">Github</a></p>
          <p><a href="https://github.com/core-player/vue-core-video-player/issues" target="_blank">FAQ</a></p>
          <p><a href="https://stackoverflow.com/questions/tagged/vue-core-video-player" target="_blank">Stackoverflow</a></p>
          <p><a href="hhttps://segmentfault.com/t/vue-core-video-player" target="_blank">Segmentfault</a></p>
          <p><a href="https://twitter.com/hashtag/vueCoreVideoPlayer?src=hashtag_click" target="_blank">Twitter</a></p>
          <p><a href="https://www.facebook.com/messages/t/3426412590762528" target="_blank">Facebook</a></p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'Foot'
}
</script>

