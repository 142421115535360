<template>
  <div id="Google">
    <b-button
      class="btn btn-block googleBtn mx-1 px-1"
      @click="socialGoogleLogin"
    >
      <img
        :src="iconGoogle"
        width="30px"
        height="20px"
        style="position: relative; left: -3%"
      />Google</b-button
    >
  </div>
</template>
<script>
import firebase from "firebase";
import { mapState } from "vuex";
import Swal from "sweetalert2";
import iconGoogle from "@/assets/iconGoogle.svg";
export default {
  data() {
    return {
      iconGoogle: iconGoogle,
    };
  },
  name: "Google",
  computed: {
    ...mapState({
      access_token: (state) => state.Auth.access_token,
      username: (state) => state.Auth.username,
      userData: (state) => state.Auth.userData,
    }),
  },
  methods: {
    socialGoogleLogin: function () {
      const provide = new firebase.auth.GoogleAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provide)
        .then((result) => {
          console.log("result", result);
          if (result.credential) {
            /** @type {firebase.auth.OAuthCredential} */
            var credential = result.credential;

            // This gives you a Google Access Token. You can use it to access the Google API.
            var token = credential.accessToken;
            console.log("token", token);
            if (token) {
              this.goLogin(token);
            }
            // ...
          }
          // create user in db
          let obj = {
            google_id: result.additionalUserInfo.profile.id,
            fullname: result.additionalUserInfo.profile.name,
            email: result.additionalUserInfo.profile.email,
            profile_image: result.additionalUserInfo.profile.picture,
            user_type_id: 1,
          };
          console.log(obj);
        })
        .catch((err) => {
          console.log("err", err);
        });
    },
    async goLogin(data) {
      const path = "/";
      await this.$store
        .dispatch("Auth/loginGoogle", data)
        .then((res) => {
          if (res.code == 200) {
            Swal.fire({
              title: "สำเร็จ!",
              confirmButtonText: `ตกลง`,
              icon: "success",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$router.push(path);
              }
            });
          }
        })
        .catch((e) => {
          console.log("response", e);
          Swal.fire(
            "ผิดพลาด!",
            "ไม่สามารถเข้าสู่ระบบได้ โปรดตรวจสอบอีเมล/รหัสผ่าน",
            "error"
          );
        });
    },
  },
};
</script>
<style lang="scss">
#Google {
  .googleBtn {
    background-color: white;
    color: black;
    font-weight: bold;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .googleBtn:hover {
    background-color: #f84e41;
    color: white;
    font-weight: bold;
  }
}
</style>
