<template>
  <div id="CourseContent" class="mx-5 my-3">
    <b-row class="text-center mt-3">
      <b-col lg="12" md="12" sm="12" cols="12">
        <!--        <img :src="img" width="100%" height="auto" style="max-width: 1080px" />-->
      </b-col>
    </b-row>
    <b-row>
      <b-col class="text-center text-sm-center my-3">
        <b style="font-size: 26px" class="">{{ this.course.name }}</b>
      </b-col>
    </b-row>
    <b-row class="text-center">
      <b-col lg="8" md="8" sm="8" cols="12" class="my-3">
        <vue-plyr>
          <video
            controls
            crossorigin
            playsinline
            :data-poster="this.poster_video"
          >
            <source size="1080" :src="this.video_about" type="video/mp4" />
          </video>
        </vue-plyr>
      </b-col>
      <b-col lg="4" md="4" sm="4" cols="12" class="my-3">
        <teacher :dataTeacher="this.course.creator" />
      </b-col>
    </b-row>
    <!--    <div class="d-flex flex-wrap">-->
    <!--      <div class="col-lg-8 col-md-8 col-sm-5 mb-5">-->
    <!--        <vue-plyr>-->
    <!--          <video-->
    <!--            controls-->
    <!--            crossorigin-->
    <!--            playsinline-->
    <!--            :data-poster="this.poster_video"-->
    <!--          >-->
    <!--            <source size="1080" :src="this.video_about" type="video/mp4" />-->
    <!--          </video>-->
    <!--        </vue-plyr>-->
    <!--      </div>-->
    <!--      <div class="col-lg-4 col-md-4 col-sm-2 mb-5">-->
    <!--        <teacher :dataTeacher="this.course.creator" />-->
    <!--      </div>-->
    <!--    </div>-->
    <b-row>
      <b-col lg="12" md="12" sm="12" cols="12">
        <div>
          <b-tabs content-class="mt-5" align="center">
            <b-tab title="รายละเอียด" active>
              <p v-html="this.course.description"></p>
            </b-tab>
            <b-tab title="บทเรียน">
              <b>เนื้อหาของคอร์สนี้</b>
              <span
                v-for="course_lessons in this.course.lessons"
                :key="course_lessons.id"
              >
                <b-row class="mt-3">
                  <b-col lg="12">
                    <b-row style="background-color: #7a7a7a; color: white">
                      <p class="my-1 mx-2">{{ course_lessons.name }}</p>
                    </b-row>
                    <span
                      v-for="child_lessons_data in course_lessons.child_lessons"
                      :key="child_lessons_data.id"
                    >
                      <b-row style="background-color: white; color: black">
                        <p class="my-1 mx-2">{{ child_lessons_data.name }}</p>
                      </b-row>
                    </span>
                    <br />
                  </b-col>
                </b-row>
              </span>
            </b-tab>
            <b-tab title="รายละเอียดการชำระเงิน" disabled
              ><p>I'm the second tab</p></b-tab
            >
          </b-tabs>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import img from "./../../public/temp2.jpeg";
import img2 from "./../../public/temp.jpg";
import { mapState } from "vuex";
import video from "./../../public/lifeIsWet.mp4";
import teacher from "@/components/teacher";
export default {
  name: "CourseContent",
  data() {
    return {
      img: img,
      img2: img2,
      video_about: video,
      poster_video: img,
    };
  },
  computed: {
    ...mapState({
      course: (state) => {
        return state.Courses.course;
      },
    }),
  },
  created() {
    this.getContent();
  },
  components: { teacher },
  methods: {
    async getContent() {
      const id = this.$route.query.id;
      if (id) {
        await this.$store.dispatch("Courses/getCourse", id);
      } else {
        this.$router.push("/");
      }
    },
  },
};
</script>
<style lang="scss">
.nav-link {
  color: #000000;
}
.nav-tabs .nav-link.active {
  border-top: 1px solid #de7033 !important;
  border-right: 1px solid #de7033 !important;
  border-left: 1px solid #de7033 !important;
  color: #de7033 !important;
}
.nav-link.active:hover {
  color: white !important;
}
.nav-tabs {
  border-bottom: 1px solid #de7033 !important;
}
</style>
