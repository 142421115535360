<template>
  <div id="Facebook">
    <b-button
      class="btn btn-block facebookBtn mx-1 px-1"
      @click="socialFacecbookLogin"
      ><i class="mx-1 fa fa-facebook"></i> Facebook</b-button
    >
  </div>
</template>
<script>
import firebase from "firebase";
import Swal from "sweetalert2";
export default {
  name: "Facebook",
  methods: {
    async socialFacecbookLogin() {
      await this.loadFacebookSDK(document, "script", "facebook-jssdk");
      await this.initFacebook();
      const provide = new firebase.auth.FacebookAuthProvider();
      firebase
        .auth()
        .signInWithPopup(provide)
        .then((result) => {
          // create user in db
          console.log("result", result);
          let obj = {
            facebook_id: result.additionalUserInfo.profile.id,
            fullname: result.additionalUserInfo.profile.name,
            email: result.additionalUserInfo.profile.email,
            profile_image: result.user.photoURL + "?height=500",
            user_type_id: 1,
          };
          if (obj.email && obj.facebook_id) {
            this.goLoginFacebook(obj);
          }
          // console.log(obj);
        })
        .catch((err) => {
          alert("Oops. " + err.message);
        });
    },
    async initFacebook() {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: "932195234193471",
          cookie: true,
          xfbml: true,
          version: "v9.0",
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    async goLoginFacebook(data) {
      const path = "/";
      await this.$store
        .dispatch("Auth/loginFacebook", data)
        .then((res) => {
          if (res.code == 200) {
            Swal.fire({
              title: "สำเร็จ!",
              confirmButtonText: `ตกลง`,
              icon: "success",
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.$router.push(path);
              }
            });
          }
        })
        .catch((e) => {
          console.log("response", e);
          Swal.fire(
            "ผิดพลาด!",
            "ไม่สามารถเข้าสู่ระบบได้ โปรดตรวจสอบอีเมล/รหัสผ่าน",
            "error"
          );
        });
    },
  },
};
</script>
<style lang="scss">
#Facebook {
  .facebookBtn {
    height: 38.2px;
    background-color: #3b579d;
    color: white;
    font-weight: bold;
    border-radius: 10px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .facebookBtn:hover {
    background-color: #204ec3;
  }
}
</style>
